export default {
  data() {
    return {
      toggleFilter: false,
      };
  },
  methods: {
    toggle() {
      this.toggleFilter = !this.toggleFilter
    },
    close() {
      this.toggleFilter = false
    }
  }
}